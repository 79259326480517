import { authReducer } from "./reducers/authReducer";
import { configureStore } from "@reduxjs/toolkit";
import { adminUsersReducer } from "./reducers/adminUsersReducer";
import { studioUserReducer } from "./reducers/studioUserReducer";
import { changeStudioUserTypeReducer } from "./reducers/changeStudioUserTypeReducer";
import { adminSingleUserReducer } from "./reducers/adminSingleUserReducer"
import { studioSingleUserReducer } from "./reducers/studioSingleUserReducer";
import { studioResetPasswordReducer } from "./reducers/studioUserResetPasswordReducer";
import { studioUserInvoiceReducer } from "./reducers/studioUserInvoiceReducer";
import { studioUserEditReducer } from "./reducers/studioUserEditReducer";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    studioUserReducer: studioUserReducer,
    changeStudioUserTypeReducer: changeStudioUserTypeReducer,
    studioSingleUserReducer: studioSingleUserReducer,
    studioUserEditReducer: studioUserEditReducer,
    studioResetPasswordReducer: studioResetPasswordReducer,
    studioUserInvoiceReducer:studioUserInvoiceReducer,
    adminUsersReducer: adminUsersReducer,
    adminSingleUserReducer: adminSingleUserReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
