import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router"
import { NavLinks } from "../interfaces";
import { sideNavList } from "./sideNavList";
import { useAppSelector } from "../store/reduxHooks";

interface IProps {
  sidebarColllapse: boolean;
}

const { SubMenu } = Menu;

function Sidenav({ sidebarColllapse }: IProps) {
  const location = useLocation();
  const [menuKeys, setMenuKeys] = useState({
    selectedKey: location.pathname,
    openKey: "/" + location.pathname.split("/")[1],
  });
  const { user } = useAppSelector((state: any) => state.authReducer);

  let history = useHistory();
  let selectedPathName = location.pathname;
  let selectedPathUrl = selectedPathName.split('/').slice(4, 5).join("/");
  let selectedPathEditUrl = selectedPathName.split('/').slice(5, 6).join("/");
  let adminEditURL = `/users/admin/account-info/edit/${selectedPathEditUrl}`
  let adminInfoURL = `/users/admin/account-info/${selectedPathUrl}`
  let studioEditURL = `/users/studio/account-info/${selectedPathUrl}/edit`
  let studioInfoURL = `/users/studio/account-info/${selectedPathUrl}`
  


  useEffect(() => {
    setMenuKeys((keys) => ({
      ...keys,
      selectedKey: location.pathname.split("/").slice(0, 3).join("/"),
    }));
  }, [location]);

  const linkHandler = (link: any) => {
    history.push(`${link}`);
  }

  return (
    <div>
      <Sider trigger={null} collapsible collapsed={sidebarColllapse}>
        <Menu
          mode={sidebarColllapse ? "vertical" : "inline"}
          defaultOpenKeys={["/certificates"]}
          selectedKeys={[menuKeys.selectedKey]}
        >
          {sideNavList.map((navLink: NavLinks) => {
            if (navLink.children) {
              return (
                <SubMenu
                  icon={navLink.icon}
                  key={navLink.href}
                  title={navLink.title}
                  className={`${location.pathname === '/' && navLink.title === 'Users' ? 'selectedUserItem' : ''} ${location.pathname === '/users/studio' && navLink.title === 'Users' ? 'selectedUserItem' : ''}${location.pathname === '/users/admin' && navLink.title === 'Users' ? 'selectedUserItem' : ''} ${location.pathname === '/users/admin/create' && navLink.title === 'Users'  ? 'selectedUserItem' : ''} ${location.pathname === adminInfoURL && navLink.title === 'Users'  ? 'selectedUserItem' : ''} ${location.pathname === adminEditURL && navLink.title === 'Users'  ? 'selectedUserItem' : ''} ${location.pathname === studioEditURL && navLink.title === 'Users'  ? 'selectedUserItem' : ''} ${location.pathname === studioInfoURL && navLink.title === 'Users'  ? 'selectedUserItem' : ''}`}
                >
                  {navLink.children.map((childrenLink: NavLinks) => (
                    <div className={`${location.pathname === '/' && navLink.title === 'Users'  && childrenLink.title === 'Studio' ? 'selectedStudioItem' : ''}${location.pathname === '/users/studio' && navLink.title === 'Users'  && childrenLink.title === 'Studio' ? 'selectedStudioItem' : ''}${location.pathname === '/users/admin' && navLink.title === 'Users' && childrenLink.title === 'Admin' ? 'selectedAdminItem' : ''} ${location.pathname === '/users/admin/create' && navLink.title === 'Users' && childrenLink.title === 'Admin' ? 'selectedAdminItem' : ''} ${location.pathname === '/users/admin/create' && navLink.title === 'Users' && childrenLink.title === 'Admin' ? 'selectedAdminItem' : ''} ${location.pathname === adminInfoURL && navLink.title === 'Users' && childrenLink.title === 'Admin' ? 'selectedAdminItem' : ''} ${location.pathname === adminEditURL && navLink.title === 'Users' && childrenLink.title === 'Admin' ? 'selectedAdminItem' : ''} ${location.pathname === studioInfoURL && navLink.title === 'Users' && childrenLink.title === 'Studio' ? 'selectedStudioItem' : ''} ${location.pathname ===studioEditURL && navLink.title === 'Users' && childrenLink.title === 'Studio' ? 'selectedStudioItem' : ''}`}>
                        <Menu.Item key={childrenLink.href} icon={childrenLink.icon} >
                           <Link to={childrenLink.href}>{childrenLink.title}</Link>
                        </Menu.Item>
                    </div>
                    
                  ))}
                </SubMenu>
              );
            }
            return (
              <div className={`${location.pathname === '/Admin/view-permission' && navLink.title === 'Roles' ? 'selectedRoleItem' : ''} ${location.pathname === '/Super Admin/view-permission' && navLink.title === 'Roles' ? 'selectedRoleItem' : ''} ${location.pathname === '/roles' && navLink.title === 'Roles' ? 'selectedRoleItem' : ''}`}>
                <Menu.Item key={navLink.href} icon={navLink.icon} className={navLink.className} onClick={linkHandler.bind(null, navLink.href)}>
                {navLink.title}
                </Menu.Item>
              </div>
              
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}

export default Sidenav;
