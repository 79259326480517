import { Image } from "antd";
import { NavLinks } from "../interfaces";
import dashIcon from '../assets/images/icons/dashboard.svg';
import userIcon from '../assets/images/icons/user.svg';
import userIconActive from '../assets/images/icons/user-active.svg';
import roleIcon from '../assets/images/icons/roles.svg';
import roleIconActive from '../assets/images/icons/roles-active.svg';

export const sideNavList: NavLinks[] = [
  { href: "/", icon: <Image preview={false} src={dashIcon} />, title: "Dashboard", className: "disabled" },
  {
    href: "/certificates",
    icon: <div className="user-menus d-flex"><span className="user-default"><Image preview={false} src={userIcon} /></span><span className="user-selected"><Image preview={false} src={userIconActive} /></span></div>,
    title: "Users",
    children: [
      {
        href: "/users/studio",
        icon: '',
        title: "Studio",
      },
      {
        href: "/users/admin",
        icon: '',
        title: "Admin",
      },
    ],
  },
  { href: "/roles", icon: <div className="user-menus d-flex"><span className="user-default"><Image src={roleIcon} preview={false} /></span><span className="user-selected"><Image src={roleIconActive} preview={false} /></span></div>, title: "Roles" },
];
