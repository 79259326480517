import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

export const mainRoutesList: IRouteItem[] = [
  {
    name: "Dashboard",
    path: "/",
    LazyComponent: lazy(() => import("../pages/Users/Studio/Studio")),
    exact: true,
  },
  {
    name: "Profile",
    path: "/profile",
    LazyComponent: lazy(() => import("../pages/Profile/Profile")),
    exact: true,
  },
  {
    name: "Roles",
    path: "/roles",
    LazyComponent: lazy(() => import("../pages/Roles/Roles")),
    exact: true,
  },
  {
    name: "View Permission",
    path: "/:roles/view-permission",
    LazyComponent: lazy(() => import("../pages/Roles/Components/ViewPermission")),
    exact: true,
  },
  {
    name: "Studio",
    path: "/users/studio",
    LazyComponent: lazy(() => import("../pages/Users/Studio/Studio")),
    exact: true,
  },
  {
    name: "View Account Info",
    path: "/users/studio/account-info/:id",
    LazyComponent: lazy(() => import("../pages/Users/Studio/Components/AccountInfo")),
    exact: true,
  },
  {
    name: "Edit Account Info",
    path: "/users/studio/account-info/:id/edit",
    LazyComponent: lazy(() => import("../pages/Users/Studio/Components/EditAccountInfo")),
    exact: true,
  },
  {
    name: "Admin",
    path: "/users/admin",
    LazyComponent: lazy(() => import("../pages/Users/Admin/Admin")),
    exact: true,
  },
  {
    name: "Create Admin",
    path: "/users/admin/create",
    LazyComponent: lazy(() => import("../pages/Users/Admin/CreateAdmin")),
    exact: true,
  },
  {
    name: "Edit Admin",
    path: "/users/admin/account-info/edit/:id",
    LazyComponent: lazy(() => import("../pages/Users/Admin/EditAdmin")),
    exact: true,
  },
  {
    name: "View Account Info",
    path: "/users/admin/account-info/:id",
    LazyComponent: lazy(() => import("../pages/Users/Admin/Components/AdminAccountInfo")),
    exact: true,
  },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route) =>
    routerDom.push(
      <Route
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const mainRoutes = parseRoutes(mainRoutesList);
