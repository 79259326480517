import { Image } from 'antd'
import React from 'react'
import catSvg from '../../assets/images/Cat.svg';
import logo from '../../assets/images/fe_logo.svg';

function PageUnavailable() {
    return (
        <div className="mobile-block">
            <div className="mobile_device d-flex align-items-center">
                <div className="mobile_device_inner">
                    <p>Thanks for your patience!</p>
                    <div className="mobile_device_img">
                        <Image src={catSvg} alt="Logo" className="img-fluid" preview={false} />
                        <Image src={logo} alt="Logo" className="img-fluid" preview={false} />
                    </div>
                    <p className="text-block">is not currently available on mobile devices.</p>
                    <p className="text-block">So..come back and visit cms.brandxr.studio from your desktop browser.</p>
                </div>
            </div>
        </div>
    )
}

export default PageUnavailable
