import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import "./App.less";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
import { store } from "./store/store";
// import 'antd/dist/antd.compact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fonts/fonts.css";
import "./assets/less/main.less";
// import './assets/sass/main.dark.css';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
